import { Table, Dropdown } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import { format, parse } from "date-fns";
import { useEffect, useState, useMemo } from "react";
import queryString from "query-string";
// import { CSVLink } from "react-csv";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { Link, useNavigate } from "react-router-dom";

import { ExchangeFunds } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
// import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { fetchActionsUtil } from "../../utils/helpers";
import CreatePlantModal from "../modals/CreatePlant";
import CreateViewStorageBinsModal from "../modals/CreateViewStorageBins";
import EditPlantModal from "../modals/EditPlant";

function Plants() {
  useScrollTop();
  const initialFilterParams = {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  const { backendUrl } = useAuth();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openStorageBinModal, setOpenStorageBinModal] = useState(false);
  const [singlePlant, setSinglePlant] = useState({
    plantName: "",
    storageBins: [],
    plantId: "",
  });

  const [queryParams, setQueryParams] = useQueryParams({
    // page: 1,
    // limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const navigate = useNavigate();

  const { data, refetch, isFetching } = useQuery(
    ["FETCH_WAREHOUSE", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/production/plants?${queryString.stringify(
          queryParams
        )}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  const changeData = useMemo(() => {
    return data?.allPlants?.map((d) => ({ ...d[0] }));
  }, [data]);
  //   console.log(changeData);
  const onGoToWarehouse = (namewh, cater, whID) => {
    // window.localStorage.setItem(
    //   "plantName",
    //   JSON.stringify({ namewh, cater, whID })
    // );
    // navigate("/warehouse-inventory/inventry-entry");
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <main className="cash-book table-sticky-header">
      <div className="content">
        <header className="container">
          <h1>
            Plants
            <button
              title="Refresh"
              onClick={() => refetch()}
              className="btn text-primary"
            >
              <CachedIcon />
            </button>
          </h1>
          <div className="actions mr-5">
            {/* <CSVLink
              className="btn print d-none"
              filename={`General Ledger Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            /> */}

            <div className="position-relative">
              <RsDateRangePicker
                placement="bottomStart"
                value={
                  filterParams.startDate && filterParams.endDate
                    ? [
                        parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                        parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                    : []
                }
                onClean={() => clearDateRange()}
                onOk={(date) => filterByDateRange(date)}
              />
            </div>

            <button
              onClick={() => setOpenCreateModal(true)}
              className="btn bg-primary text-white"
            >
              Create New <ExchangeFunds color="white" />
            </button>
            {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                queryParams
              )}`}
              className="btn print mx-4"
            >
              PDF <PDFIcon color="#ff0000" />
            </a>
            <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button> */}
          </div>
        </header>

        <div>
          <Table
            // responsive
            borderless
            striped
            className="product-table container"
          >
            <thead className="position-sticky top-0">
              <tr>
                <th />
                <th>Plant Name</th>
                <th>Manufacturing Type</th>
                <th>Product Line</th>
                <th>Account</th>
                <th>Storage Bins</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {changeData?.map((ek, i) => (
                <tr
                  key={i}
                  onDoubleClick={() =>
                    onGoToWarehouse(ek.W_name, ek.W_Category, ek.W_ID)
                  }
                  className="p-cursor"
                >
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="bg-white border-0"
                        bsPrefix="print more"
                      >
                        <DotsVerticalIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="dropdown-with-icons"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={() =>
                            onGoToWarehouse(
                              ek.Plant_Name,
                              ek.W_Category,
                              ek.W_ID
                            )
                          }
                          className="p-cursor"
                        >
                          Open
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="div"
                          className="p-cursor"
                          onClick={() => {
                            setSinglePlant({
                              ...singlePlant,
                              plantName: ek.Plant_Name,
                              plantId: ek.Plant_ID,
                            });
                            setOpenEditModal(true);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td onClick={() => setOpenEditModal(true)}>
                    {ek.Plant_Name}
                  </td>
                  <td>{ek?.Manu_Type}</td>
                  <td>{ek?.Prod_Line}</td>
                  <td>{ek?.Plant_Account?.Description}</td>
                  <td>
                    <div
                      className="text-white position-relative"
                      onClick={() => {
                        setSinglePlant({
                          plantName: ek.Plant_Name,
                          storageBins: ek?.storageBin,
                          plantId: ek.Plant_ID,
                        });
                        setOpenStorageBinModal(true);
                      }}
                    >
                      {ek?.storageBin?.map(
                        (u, i, arr) =>
                          i < 2 && (
                            <span class="badge text-bg-primary" key={i}>
                              {u?.Storage_Name?.split("")[0]}
                            </span>
                          )
                      )}
                      {ek?.storageBin?.length > 2 ? (
                        <span class="badge text-bg-primary" key={i}>{`+${ek
                          ?.storageBin?.length - 2}`}</span>
                      ) : null}
                    </div>
                  </td>
                  <td>{ek?.Date}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {changeData?.length < 1 && <NoTableItem queryParams={queryParams} />}
        <div className="pagination">
          {/* Left */}
          {/* <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div> */}

          {/* right */}
          {/* <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div> */}
        </div>
      </div>
      <ModalLoader show={isFetching} />
      <CreatePlantModal
        show={openCreateModal}
        onHide={() => setOpenCreateModal(false)}
        refetch={refetch}
      />
      <CreateViewStorageBinsModal
        show={openStorageBinModal}
        onHide={() => setOpenStorageBinModal(false)}
        refetch={refetch}
        singlePlant={singlePlant}
      />
      <EditPlantModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        refetch={refetch}
        singlePlant={singlePlant.plantName}
        plantId={singlePlant.plantId}
      />
    </main>
  );
}

export default Plants;
