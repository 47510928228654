import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  convertMomentDate,
  customerFullName,
  maxTopPopperConfig,
  reactSelectTheme,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import AccountPlusOutlineIcon from "mdi-react/AccountPlusOutlineIcon";
import { isEmpty } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, {
  useEffectOnce,
  useToggle,
  useUpdateEffect,
} from "../../utils/hooks";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import { Popover } from "react-tiny-popover";
import AddCustomerItemModal from "./AddCustomerItemModal";
import moment, { isMoment } from "moment";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

const taxOptions = [
  {
    label: "None",
    value: "None",
    percentage: 0,
  },
  {
    label: "VAT",
    value: "VAT",
    percentage: 0.075,
  },
  {
    label: "WHT",
    value: "WHT",
    percentage: 0.05,
  },
  {
    label: "NCD",
    value: "NCD",
    percentage: 0.01,
  },
];

export default function CementEditRow({
  sale,
  driverType,
  drivers = [],
  index,
  salesIndex,
  handleEditCustomer,
  handleRemoveCustomer,
  editSale,
}) {
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
    {
      icon: <CustomerAccountSelectIcon />,
      label: "Customer Account",
      value: "Customer Account",
    },
    {
      icon: <CashSelectIcon />,
      label: "Loyalty Account",
      value: "Loyalty Account",
    },
    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    /* {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    }, */
  ];

  // Date toggles -- Hot fix
  const [Date_LogToggleState, setDate_LogToggleState] = useState(false);
  const [loadingDateToggleState, setLoadingDateToggleState] = useState(false);
  const [deliveryDateToggleState, setDeliveryDateToggleState] = useState(false);
  const [salesDateToggleState, setSalesDateToggleState] = useState(false);

  const initialValues = {
    tax: "",
    taxType: "None",
    splitPaymentType: "",
    // invoiceCat: "",
    OverwriteOfficer: "Retail",
    chequeNumber: "",
    BankName: "",
    cashAmount: "",

    proformaNumber: "",
    dueIn: 1,
    PaymentType: "Credit",
    Quantity: 300,
    PriceSold: 0,
    Discount: 0,
    loadingDate: moment(),
    deliveryDate: moment(),
    salesDate: moment(),
    Date_Log: moment(),
    truckNumber: "",

    selectedDriverType: "Self Collection",
    driver: "",
    driverPhoneNumber: "",

    supplyNow: true,

    shippingCost: "",
    loadingCharge: "",
    offloadingCharge: "",
    Item_Desc: "",
    posCharge: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      // PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
      /*  Quantity: yup
        .string()
        .required("required")
        .min(1),
      PriceSold: yup.string().required("required"), */
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
      /* tableData[editedItemIndex].sales[editedSaleIndex].Quantity =
        values.Quantity;
      setTableData([...tableData]);
      unsetActiveRow(); */
    },
    onReset: () => {},
  });

  const submitForm = () => {
    //  formik.submitForm();
  };

  const formValues = useDebounce(formik.values, 500);

  const populateForm = () => {
    formik.setValues({
      ...formik.values,
      UnitPrice: sale.UnitPrice,
      amountPaid: 0,
      PriceSold: sale.UnitPrice,

      loadingDate: sale.loadingDate,
      deliveryDate: sale.deliveryDate,
      salesDate: sale.salesDate,
      truckNumber: sale.truckNumber,

      supplyNow: sale.supplyNow,
      shippingCost: sale.shippingCost,
      loadingCharge: sale.loadingCharge,
      offloadingCharge: sale.offloadingCharge,

      selectedDriverType: sale.selectedDriverType,
      driver: sale.driver,
      driverPhoneNumber: sale.driverPhoneNumber,

      Item_Desc: sale.Item_Desc,

      Quantity: sale.Quantity,
    });
  };

  useEffectOnce(() => {
    populateForm();
  });

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
  ]);

  const subTotal = useMemo(() => {
    if (formik.values.Discount) {
      return (
        currency(formik.values.UnitPrice, {
          symbol: "",
          separator: "",
        })
          .multiply(formik.values.Quantity)
          /* .add(formik.values.loadingCharge)
        .add(formik.values.offloadingCharge) */
          .format()
      );
    } else {
      return (
        currency(formik.values.PriceSold, {
          symbol: "",
          separator: "",
        })
          .multiply(formik.values.Quantity)
          /* .add(formik.values.loadingCharge)
        .add(formik.values.offloadingCharge) */
          .format()
      );
    }
  }, [
    formik.values.UnitPrice,
    formik.values.PriceSold,
    formik.values.Discount,
    formik.values.Quantity,
    /* 
    formik.values.loadingCharge,
    formik.values.offloadingCharge, */
  ]);

  const discount = useMemo(() => {
    return currency(formik.values.Discount, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.Discount, formik.values.Quantity]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType.percentage)
      .format();
  }, [subTotal, formik.values.taxType, taxOptions]);

  const amountDue = useMemo(() => {
    const sum = currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .subtract(discount)
      .multiply(100)
      .divide(100)
      .add(taxValue)
      .add(chargesAfterTax)
      .format();

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", sum);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", sum);

    return sum;
  }, [subTotal, discount, taxValue]);

  useEffect(() => {
    if (formik.values.Discount) {
      const finalSellingPrice = currency(formik.values.UnitPrice, {
        symbol: "",
        separator: "",
      })
        .subtract(formik.values.Discount)
        .format();
      formik.setFieldValue("PriceSold", finalSellingPrice);
      // formik.setFieldValue("amountPaid", finalSellingPrice);
    }
  }, [formik.values.Discount, formik.values.UnitPrice]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const balance = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.amountPaid)
      .format();
  }, [amountDue, formik.values.amountPaid]);

  const profit = useMemo(() => {
    return currency(formik.values.PriceSold, {
      symbol: "",
      separator: "",
    })
      .subtract(sale.UnitCost)
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.PriceSold, sale.UnitCost, formik.values.Quantity]);

  const costOFSales = useMemo(() => {
    return currency(sale.UnitCost, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.Quantity]);

  useEffect(() => {
    // console.log(formValues, formik.values.supplyNow);
    // formik values is set empty to undefined
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }
    editSale({
      index,
      salesIndex,
      formValues: {
        ...formValues,
        Profit: profit,
        balance,
        SubTotal: subTotal,
        profit,
        amountDue,
        invoiceCat: sale.Product_Name,
        taxValue,
        costOFSales,
      },
    });
  }, [formValues, profit, balance, subTotal, amountDue, taxValue, costOFSales]);

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", amountDue);
    }
  };

  return (
    <tr className="p-cursor bg-white active-invoice-row popup">
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="bg-white border-0"
            bsPrefix="print more"
            type="button"
          >
            <DotsVeritcalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => handleRemoveCustomer(index, salesIndex)}
            >
              <DeleteIcon />
              Remove Customer
            </Dropdown.Item>
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>

      <td>
        <Button
          onClick={() => handleEditCustomer(index, salesIndex)}
          variant={!sale.customer ? "outline-primary" : ""}
          className="border-0"
          title="Select Customer"
          type="button"
        >
          {sale.customer?.LastName || "Select Customer"}
        </Button>
      </td>
      <td>{sale.Bar_Code}</td>
      <td>{sale.Item_Name}</td>
      <td>
        <NumberCustomInput
          name="Quantity"
          value={formik.values.Quantity}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
          onBlur={() => formik.setFieldTouched("Quantity", true)}
          autocomplete="off"
        />
        {formik.touched.Quantity && !!formik.errors.Quantity ? (
          <span className="custom-invalid-feedback">
            {formik.errors.Quantity}
          </span>
        ) : null}
      </td>
      <td>
        <Form.Control
          type="text"
          placeholder="Enter Location"
          name="Item_Desc"
          value={formik.values.Item_Desc}
          onChange={formik.handleChange}
          isInvalid={formik.touched.Item_Desc && !!formik.errors.Item_Desc}
        />
      </td>
      <td>
        <Form.Control
          type="text"
          placeholder="Enter Region"
          name="region"
          value={formik.values.region}
          onChange={formik.handleChange}
          isInvalid={formik.touched.region && !!formik.errors.region}
        />
      </td>
      <td>
        <Popover
          reposition={false}
          isOpen={Date_LogToggleState}
          onClickOutside={() => setDate_LogToggleState(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MMM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="Date_Log"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.Date_Log && !!formik.errors.Date_Log
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.Date_Log}
              onChange={(date) => {
                formik.setFieldValue("Date_Log", date, true);
                setDate_LogToggleState(false);
              }}
              onBlur={() => formik.setFieldTouched("Date_Log", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDate_LogToggleState(!Date_LogToggleState)}
            value={convertMomentDate(formik.values.Date_Log)}
          />
        </Popover>
      </td>
      <td>
        <CurrencyCustomInput
          name="PriceSold"
          placeholder="0.00"
          currencySymbol=""
          value={formik.values.PriceSold}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
            formik.setFieldValue("Discount", 0);
          }}
          isInvalid={formik.touched.PriceSold && !!formik.errors.PriceSold}
          onBlur={() => formik.setFieldTouched("PriceSold", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
        />
        {formik.touched.PriceSold && !!formik.errors.PriceSold ? (
          <span className="custom-invalid-feedback">
            {formik.errors.PriceSold}
          </span>
        ) : null}
      </td>

      <td>
        <CurrencyCustomInput
          name="Discount"
          placeholder="0.00"
          currencySymbol=""
          value={formik.values.Discount}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={formik.touched.Discount && !!formik.errors.Discount}
          onBlur={() => formik.setFieldTouched("Discount", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
        />
        {formik.touched.Discount && !!formik.errors.Discount ? (
          <span className="custom-invalid-feedback">
            {formik.errors.Discount}
          </span>
        ) : null}
      </td>
      <td>{sale.Product_Name}</td>
      <td>
        {currency(sale.UnitPrice, {
          symbol: "",
        }).format()}
      </td>
      <td>
        {currency(profit, {
          symbol: "",
        }).format()}
      </td>
      <td>
        {currency(sale.UnitCost, {
          symbol: "",
        })
          .multiply(formik.values.Quantity)
          .format()}
      </td>
      <td>{sale.Item_Type} </td>
      <td>
        <Popover
          reposition={false}
          isOpen={loadingDateToggleState}
          onClickOutside={() => setLoadingDateToggleState(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              dateFormat="MMM DD, YYYY"
              name="loadingDate"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.loadingDate && !!formik.errors.loadingDate
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.loadingDate}
              onChange={(date) => {
                formik.setFieldValue("loadingDate", date, true);
                setLoadingDateToggleState(false);
              }}
              onBlur={() => formik.setFieldTouched("loadingDate", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setLoadingDateToggleState(!loadingDateToggleState)}
            value={convertMomentDate(formik.values.loadingDate)}
          />
        </Popover>
      </td>
      <td>
        <Popover
          reposition={false}
          key={index}
          isOpen={deliveryDateToggleState}
          onClickOutside={() => setDeliveryDateToggleState(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              timeFormat={false}
              // closeOnSelect={true}
              // closeOnClickOutside={true}
              dateFormat="MMM DD, YYYY"
              name="deliveryDate"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.deliveryDate && !!formik.errors.deliveryDate
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              value={formik.values.deliveryDate}
              input={false}
              onChange={(date) => {
                formik.setFieldValue("deliveryDate", date, true);
                setDeliveryDateToggleState(false);
              }}
              onBlur={() => formik.setFieldTouched("deliveryDate", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDeliveryDateToggleState(!deliveryDateToggleState)}
            value={convertMomentDate(formik.values.deliveryDate)}
          />
        </Popover>
      </td>
      <td>
        <Select
          classNamePrefix="form-select"
          placeholder="Select"
          menuPosition="fixed"
          menuShouldScrollIntoView={false}
          isSearchable={false}
          value={driverType.find(
            (el) => el.value === formik.values.selectedDriverType
          )}
          options={driverType}
          onChange={({ value }) =>
            formik.setFieldValue("selectedDriverType", value)
          }
        />
      </td>
      <td>
        {formik.values.selectedDriverType === "Self Collection" ? (
          <Select
            classNamePrefix="form-select"
            placeholder="Select"
            menuPosition="fixed"
            menuShouldScrollIntoView={false}
            isSearchable={true}
            value={drivers.find((el) => el.value === formik.values.driver)}
            options={drivers}
            onChange={({ value }) => formik.setFieldValue("driver", value)}
          />
        ) : (
          <Form.Control
            type="text"
            placeholder="Enter Driver's Name"
            name="driver"
            value={formik.values.driver}
            onChange={formik.handleChange}
            isInvalid={formik.touched.driver && !!formik.errors.driver}
          />
        )}
      </td>
      <td>
        <Form.Control
          type="tel"
          placeholder="Enter Phone Number"
          name="driverPhoneNumber"
          value={formik.values.driverPhoneNumber}
          onChange={formik.handleChange}
          isInvalid={
            formik.touched.driverPhoneNumber &&
            !!formik.errors.driverPhoneNumber
          }
        />
      </td>
      <td>
        {" "}
        <Form.Control
          type="tel"
          placeholder="Enter Truck Number"
          name="truckNumber"
          value={formik.values.truckNumber}
          onChange={formik.handleChange}
          isInvalid={formik.touched.truckNumber && !!formik.errors.truckNumber}
        />
      </td>
      <td>
        {" "}
        <CurrencyCustomInput
          name="loadingCharge"
          placeholder="0.00"
          currencySymbol=""
          value={formik.values.loadingCharge}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={formik.touched.loadingCharge && !!formik.errors.Discount}
          onBlur={() => formik.setFieldTouched("Discount", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
        />
        {formik.touched.loadingCharge && !!formik.errors.loadingCharge ? (
          <span className="custom-invalid-feedback">
            {formik.errors.loadingCharge}
          </span>
        ) : null}
      </td>
      <td>
        <CurrencyCustomInput
          name="offloadingCharge"
          placeholder="0.00"
          currencySymbol=""
          value={formik.values.offloadingCharge}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={
            formik.touched.offloadingCharge && !!formik.errors.offloadingCharge
          }
          onBlur={() => formik.setFieldTouched("offloadingCharge", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
        />
        {formik.touched.offloadingCharge && !!formik.errors.offloadingCharge ? (
          <span className="custom-invalid-feedback">
            {formik.errors.offloadingCharge}
          </span>
        ) : null}
      </td>

      <td>
        <div className="d-flex align-items-center gap-3">
          <div>
            <CurrencyCustomInput
              name="shippingCost"
              currencySymbol=""
              value={formik.values.shippingCost}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={
                formik.touched.shippingCost && !!formik.errors.shippingCost
              }
              onBlur={() => formik.setFieldTouched("shippingCost", true)}
              onKeyDown={(e) => e.keyCode === 13 && submitForm()}
              placeholder="0.00"
            />
            {formik.touched.shippingCost && !!formik.errors.shippingCost ? (
              <span className="custom-invalid-feedback">
                {formik.errors.shippingCost}
              </span>
            ) : null}
          </div>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Supply now"
            className="mb-3"
            name="supplyNow"
            checked={formik.values.supplyNow}
            onChange={(e) => {
              formik.handleChange(e);
              // handleSupplyNow(e.target.checked);
            }}
          />
        </div>
      </td>

      {/*  calculations   */}
      <td>
        <Popover
          reposition={false}
          key={index}
          isOpen={salesDateToggleState}
          onClickOutside={() => setSalesDateToggleState(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              timeFormat={false}
              // closeOnSelect={true}
              // closeOnClickOutside={true}
              dateFormat="MMM DD, YYYY"
              name="deliveryDate"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.salesDate && !!formik.errors.salesDate
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              value={formik.values.salsDate}
              input={false}
              onChange={(date) => {
                formik.setFieldValue("salesDate", date, true);
                setSalesDateToggleState(false);
              }}
              onBlur={() => formik.setFieldTouched("salesDate", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setSalesDateToggleState(!salesDateToggleState)}
            value={convertMomentDate(formik.values.salesDate)}
          />
        </Popover>
      </td>
      <td>
        <Form.Control
          type="text"
          placeholder="Po No."
          name="proformaNumber"
          value={formik.values.poNumber}
          onChange={formik.handleChange}
        />
      </td>
      <td>
        <Select
          classNamePrefix="form-select"
          placeholder="Select"
          menuPosition="fixed"
          menuShouldScrollIntoView={false}
          isSearchable={false}
          value={taxOptions.find((el) => el.value === formik.values.taxType)}
          options={taxOptions}
          onChange={({ value }) => formik.setFieldValue("taxType", value)}
        />
      </td>
      <td>
        <CurrencyCustomInput
          name="tax"
          value={taxValue}
          onValueChange={(value, name) => {
            //   formik.setFieldValue(name, value);
          }}
          placeholder="0.00"
          currencySymbol=""
        />
      </td>
      <td className="">
        <Select
          classNamePrefix="form-select"
          menuPlacement="bottom"
          menuPosition="fixed"
          placeholder="Choose method"
          isSearchable={false}
          value={paymentMethod.find(
            (el) => el.value === formik.values.PaymentType
          )}
          options={paymentMethod}
          onChange={({ value }) => handlePaymentMethod(value)}
          getOptionLabel={(el) => (
            <div className="label-with-icon d-flex gap-2 align-items-center">
              <span>{el.icon}</span> <span className="fw-5">{el.label}</span>
            </div>
          )}
        />

        {formik.values.PaymentType === "Customer Account" ? (
          <Form.Group className="mt-3">
            <Form.Label className="fw-6">Customer Credit Balance</Form.Label>
            <CurrencyCustomInput
              name="customerBalance"
              value={sale.customer.customerBalance}
              readOnly
              placeholder="0.00"
            />
          </Form.Group>
        ) : null}
      </td>
      <td>
        {currency(subTotal, {
          symbol: "",
        })
          .add(chargesAfterTax)
          .format()}
      </td>
      <td>
        {currency(amountDue, {
          symbol: "",
        }).format()}
      </td>
      <td>
        <CurrencyCustomInput
          name="amountPaid"
          currencySymbol=""
          value={formik.values.amountPaid}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={formik.touched.amountPaid && !!formik.errors.amountPaid}
          onBlur={() => formik.setFieldTouched("amountPaid", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
          readOnly={formik.values.PaymentType === "Customer Account"}
          placeholder="0.00"
        />
        {formik.touched.amountPaid && !!formik.errors.amountPaid ? (
          <span className="custom-invalid-feedback">
            {formik.errors.amountPaid}
          </span>
        ) : null}
      </td>
      <td>
        {currency(balance, {
          symbol: "",
        }).format()}
      </td>
      <td>
        <NumberCustomInput
          name="dueIn"
          value={formik.values.dueIn}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>
    </tr>
  );
}
