import { toast } from "react-toastify";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import ConfirmDialog from "./ConfirmDialogue";
import { useAuth } from "./../hooks/useAuth";
import PageHeader from "./PageHeader";
import { TagSimpleIcon } from "./Icons";
import { StartIcon } from "./Icons";
import { fetchActionsUtil } from "../utils/helpers";

const initials = {
  fullName: "",
  email: "",
  experience: "",
  challenges: "",
  productRate: [],
  satisfied: "",
  recommend: "",
  technical: "",
  support: "",
};

const Rates = [
  "User Interface",
  "User Experience",
  "Avaliablity",
  "Accuracy",
  "Speed",
  "Reliablity",
];

const arrayMain = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const CheckInputComponent = ({
  arrayData,
  label,
  name,
  OnChangeInput,
  lowChoose,
  highChoose,
  inputData,
}) => {
  return (
    <div className="mb-5 mx-auto">
      <label className="col-form-label fw-bold fs-5">
        {label}
        {/* */}
      </label>
      {lowChoose && highChoose && (
        <div className="d-none d-md-flex container justify-content-between">
          <div className="fs-6 mx-2 fw-bold">{lowChoose}</div>
          <div className="fs-6 fw-bold" style={{ marginRight: "23rem" }}>
            {highChoose}
          </div>
        </div>
      )}
      <div className="d-md-flex mt-3">
        {arrayData.map((el, ine) => (
          <div
            className="d-flex d-md-block align-items-baseline mb-2 mx-4"
            key={ine}
          >
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={`radio${el}`}
              style={{ height: "30px", width: "30px" }}
              value={el}
              onChange={OnChangeInput}
            />
            <p
              className={`mx-2 fw-bold ${
                parseInt(inputData) === el ? "text-primary" : ""
              } `}
              for={`radio${el}`}
            >
              {el}
            </p>
            {lowChoose && highChoose && (
              <>
                <div
                  className={`d-block d-md-none fw-bold ${
                    el === 1 ? "d-block" : "d-none"
                  } fs-6 mx-2`}
                >
                  {lowChoose}
                </div>
                <div
                  className={`d-block d-md-none fw-bold ${
                    el === 10 ? "d-block" : "d-none"
                  } fs-6 mx-2`}
                >
                  {highChoose}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const FeedBack = () => {
  const [searchParams] = useSearchParams();

  const UserName = searchParams.get("UserName");
  const Useremail = searchParams.get("Useremail");

  const { backendUrl } = useAuth();

  const [inputData, setInputData] = useState({
    ...initials,
    fullName: UserName ? UserName : "",
    email: Useremail ? Useremail : "",
  });

  const navigate = useNavigate();

  const saveFeedBack = useMutation(
    (payload) =>
      fetchActionsUtil(
        `https://gigc-test-api.invexonline.com/api/users/feedback`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Feedback sent Successfully");
        setInputData(initials);
        navigate("/feedback-thankyou");
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const OnChangeInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const OnChangeObjects = (e) => {
    const { name, type, value } = e;
    if (name === "productRate") {
      if (inputData.productRate.length > 0) {
        const index = inputData.productRate.findIndex((el) => el.type === type);
        if (index === -1) {
          inputData.productRate.push({ type, value });
        } else {
          inputData.productRate.splice(index, 1, { type, value });
        }
      } else {
        inputData.productRate.push({ type, value });
      }

      setInputData({ ...inputData, [name]: inputData.productRate });
    }
  };

  const sunmitFeedBack = async () => {
    if (inputData.fullName === "")
      return toast.error("Please input your Full Name");
    if (inputData.email === "") return toast.error("Please input your Email");
    if (
      await ConfirmDialog({
        title: "Send Feedback",
        description: "Are you sure you want to send this feedBack",
      })
    ) {
      saveFeedBack.mutate(inputData);
    }
    console.log(inputData);
  };

  return (
    <main
      className="page-outlet"
      style={{
        background:
          "linear-gradient(to bottom, #2463ae 200px, 	#2463ae 200px, 	#2463ae)",
      }}
    >
      <PageHeader
        name="Please Evaluate Invex ERP"
        description="We are glad to get your feedback, to make Invex ERP better."
        icon={<TagSimpleIcon />}
      />
      <div className="container my-auto mt-4 pb-4">
        <div className="card shadow p-4">
          <div className="card-body">
            {/*  */}
            <div className="mb-4 row">
              <div className="mb-3 col-md-6">
                <label className="col-form-label fw-bold fs-5">Full Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    value={inputData.fullName}
                    name="fullName"
                    onChange={OnChangeInput}
                  />
                </div>
              </div>
              {/*  */}
              <div className="mb-3 col-md-6">
                <label className="col-form-label fw-bold fs-5">Email</label>
                <div>
                  <input
                    type="email"
                    className="form-control"
                    value={inputData.email}
                    name="email"
                    onChange={OnChangeInput}
                  />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="mb-4 row">
              <div className="mb-3 col-md-6">
                <label className="col-form-label fw-bold fs-5">
                  What's your experience with Invex ERP
                </label>
                <div>
                  <textarea
                    name="experience"
                    onChange={OnChangeInput}
                    className="form-control"
                    cols="30"
                    rows="5"
                    value={inputData.experience}
                  />
                </div>
              </div>
              {/*  */}
              <div className="col-md-6">
                <label className="col-form-label fw-bold fs-5">
                  What are your challenges
                </label>
                <div>
                  <textarea
                    name="challenges"
                    onChange={OnChangeInput}
                    className="form-control"
                    cols="30"
                    rows="5"
                    value={inputData.challenges}
                  />
                </div>
              </div>
            </div>
            {/*  */}

            <div className="mb-5 mx-auto">
              <label className="col-form-label fw-bold fs-5">
                Rate Invex ERP
              </label>
              <div className="row">
                {Rates.map((ze, inx) => (
                  <div
                    className="d-flex align-items-center mb-3 col-md-6"
                    key={inx}
                  >
                    <p className="col-4 mx-md-3 col-md-3 fw-bold">{ze}</p>
                    {[1, 2, 3, 4, 5].map((d, i) => (
                      <label key={i}>
                        <input
                          type="radio"
                          name="productRate"
                          onClick={() =>
                            OnChangeObjects({
                              name: "productRate",
                              type: ze,
                              value: d,
                            })
                          }
                          className="d-none"
                        />
                        <StartIcon
                          index={d}
                          key={i}
                          rate={
                            inputData.productRate.find((el) => el.type === ze)
                              ?.value
                          }
                        />
                      </label>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            {/*  */}

            <CheckInputComponent
              arrayData={arrayMain}
              label="How satisfied are you with Invex ERP"
              name="satisfied"
              OnChangeInput={OnChangeInput}
              lowChoose="Not Satisfied"
              highChoose="Extremely Satisfied"
              inputData={inputData.satisfied}
            />

            {/*  */}

            <CheckInputComponent
              arrayData={arrayMain}
              label="How likely are you to recommend this product to a friend or family memeber?"
              name="recommend"
              OnChangeInput={OnChangeInput}
              lowChoose="Not Likely"
              highChoose="Extremely Likely"
              inputData={inputData.recommend}
            />

            {/*  */}

            <CheckInputComponent
              arrayData={arrayMain}
              label=" How satisfied are you with the technical support"
              name="technical"
              OnChangeInput={OnChangeInput}
              lowChoose="Not Satisfied"
              highChoose="Extremely Satisfied"
              inputData={inputData.technical}
            />

            {/*  */}
            <CheckInputComponent
              arrayData={["Yes", "No"]}
              label="Technical support available by phone?"
              name="support"
              OnChangeInput={OnChangeInput}
            />

            {/*  */}
            <div className="d-flex justify-content-center mb-3">
              <button
                className="btn btn-primary btn-lg"
                onClick={sunmitFeedBack}
              >
                {saveFeedBack.isLoading ? "Sending..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5 pb-3 ">
        <div className="d-flex justify-content-between align-items-baseline">
          <div>
            <img src="/man.png" alt="man" className="img-fluid" />
          </div>
          <div>
            <img src="/people.png" alt="people" className="img-fluid" />
          </div>
          <div>
            <img src="/women.png" alt="women" className="img-fluid" />
          </div>
        </div>
      </div>
    </main>
  );
};
