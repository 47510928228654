import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, { useSingleAndDoubleClick } from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import currency from "currency.js";
import Datetime from "react-datetime";
import { format } from "date-fns";
import { useAuth } from "../../hooks/useAuth";

const options = [
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
];

export default function SplitAtcModal({ setShowAtcModal, handleSelectedAtc }) {
  // const searchClick = useSingleAndDoubleClick(() => {}, () => alert("jd"));
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    Status: "",
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getAtcs = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/vendors/ledger?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { vendorLedgers: [] } } = useQuery(
    [queryActions.ALL_PERMITS, debouncedqueryParams],
    () => getAtcs(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      show={true}
      onHide={() => setShowAtcModal(false)}
      dialogClassName=""
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Search ATC to load</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4">
          <div className="global-search-area  flex-grow-1">
            <MagnifyIcon />
            <Form.Control
              id="queryParams-q"
              className=""
              name="q"
              value={queryParams.q}
              onChange={(e) => handleSearchQueryChange(e)}
              placeholder="Search Ref Number..."
            />
          </div>

          <Form.Group>
            <Datetime
              dateFormat="MMM DD, YYYY"
              timeFormat={false}
              value={queryParams.date}
              onChange={(date) => {
                setQueryParams({
                  ...queryParams,
                  page: 1,
                  date,
                });
              }}
              inputProps={{
                className: `date-input form-control`,
                placeholder: "Select date",
                //readOnly: true,
              }}
            />
          </Form.Group>
        </div>

        <div className="content px-3 pb-4 pe-2">
          <Table
            borderless
            striped
            responsive
            className="product-table text-nowrap"
          >
            <thead className="sticky border-bottom">
              <tr>
                <th>Ref Number</th>
                <th>Trans Date</th>
                <th>Quantity</th>
                <th>Plant/Depot</th>
                <th>Category</th>
                <th>Payment type</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data.vendorLedgers &&
                data.vendorLedgers.map((el, index) => (
                  <tr
                    key={index}
                    onClick={() => handleSelectedAtc(el)}
                    className={`p-cursor`}
                  >
                    <td>{el.Trans_ID}</td>
                    <td>
                      {el.Post_Date
                        ? format(new Date(el.Post_Date), "MMM dd, yyyy")
                        : ""}
                    </td>
                    <td>{el.Qty}</td>

                    <td>{el.Pickup_Location}</td>
                    <td>{el.Category}</td>
                    <td>{el.PaymentType}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
