import { useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Form, Button, Dropdown } from "react-bootstrap";
import { services } from "../../config";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import { useQuery } from "react-query";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";

import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { NoSelectedItemIcon } from "../Icons";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import CustomerSelectModal from "../CustomerSelectModal";
import NewCustomerModal from "../NewCustomerModal";
import { isEmpty } from "lodash";
import { useMemo } from "react";

export default function AddMeterModal({
  showMeterSelectorModal,
  setShowMeterSelectorModal,
  handleAddMeter,
  meters = [],
}) {
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const formik = useFormik({
    initialValues: {
      salesDate: moment(),
      meterId: "",
      openingMeterReading: "",
      closingMeterReading: "",
    },
    validationSchema: yup.object().shape({
      openingMeterReading: yup.string().required("required"),
      closingMeterReading: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      if (typeof values.salesDate === "string") {
        return formik.setFieldError("salesDate", "Invalid date");
      }

      if (isEmpty(selectedCustomer)) {
        return toast.error("Please select a customer");
      }

      if (isEmpty(values.meterId)) {
        return toast.error("Please select a meter");
      }

      const meter = meters.find((el) => el.value === values.meterId);

      if (Number(meter.Quantity) <= 0) {
        return toast.error(`No Stock: ${meter.Quantity}`);
      }

      console.log(meter);

      handleAddMeter({
        ...meter,
        ...values,
        customer: selectedCustomer,
      });
    },
  });

  /* const getSelectedMeter = useMemo(() => {
    return meters.find((el) => el.value === formik.values.meterId);
  }, [formik.values.meterId]); */

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowMeterSelectorModal(false)}
        dialogClassName="item-select-modal add-meter"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Add Meter Reading</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <div className="postion-relative">
              {/*  <h2 className="pt-0">Pricing Details</h2> */}
              {selectedCustomer ? (
                <div className="customer-actions d-flex justify-content-between flex-grow-1">
                  <div>
                    <h3>{selectedCustomer?.LastName}</h3>
                    <p>{selectedCustomer.Cust_ID}</p>
                  </div>

                  <div>
                    <Dropdown style={{ margin: 0 }}>
                      <Dropdown.Toggle
                        variant=""
                        className="bg-light-blue text-primary"
                        bsPrefix="change"
                      >
                        Change
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className=""
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() => setShowCustomerSelectorModal(true)}
                        >
                          Select Pump Attendant
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => setShowCreateNewCustomerModal(true)}
                        >
                          Create New Pump Attendant
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <div className="customer-actions flex-grow-1">
                  <h3>No Pump Attendant selected</h3>
                  <p>Select Pump Attendant or create new Pump Attendant .</p>

                  <div className="d-grid mt-2">
                    <Button
                      onClick={() => setShowCustomerSelectorModal(true)}
                      variant="outline-primary"
                    >
                      Select Pump Attendant
                    </Button>
                    <Button
                      onClick={() => setShowCreateNewCustomerModal(true)}
                      variant="outline-primary"
                    >
                      + Create New Pump Attendant
                    </Button>
                  </div>
                </div>
              )}
              <Form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Form.Group className="my-3 pb-1">
                  <Form.Label>Meter</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Select"
                    menuPosition="fixed"
                    menuShouldScrollIntoView={false}
                    isSearchable={false}
                    value={meters.find(
                      (el) => el.value === formik.values.meterId
                    )}
                    options={meters}
                    onChange={({ value }) =>
                      formik.setFieldValue("meterId", value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.meterId}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Opening Meter Reading</Form.Label>
                  <NumberCustomInput
                    placeholder="0"
                    name="openingMeterReading"
                    value={formik.values.openingMeterReading}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value, true);
                    }}
                    isInvalid={
                      formik.touched.openingMeterReading &&
                      !!formik.errors.openingMeterReading
                    }
                    onBlur={() =>
                      formik.setFieldTouched("openingMeterReading", true)
                    }
                  />
                  {formik.touched.openingMeterReading &&
                  !!formik.errors.openingMeterReading ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.openingMeterReading}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Closing Meter Reading</Form.Label>
                  <NumberCustomInput
                    placeholder="0"
                    name="closingMeterReading"
                    value={formik.values.closingMeterReading}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value, true);
                    }}
                    isInvalid={
                      formik.touched.closingMeterReading &&
                      !!formik.errors.closingMeterReading
                    }
                    onBlur={() =>
                      formik.setFieldTouched("closingMeterReading", true)
                    }
                  />
                  {formik.touched.closingMeterReading &&
                  !!formik.errors.closingMeterReading ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.closingMeterReading}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1" htmlFor="salesDate">
                    Date
                  </Form.Label>
                  <Datetime
                    dateFormat="MMM DD, YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    name="salesDate"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.salesDate && !!formik.errors.salesDate
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",

                      readOnly: true,
                    }}
                    value={formik.values.salesDate}
                    onChange={(date) => {
                      formik.setFieldValue("salesDate", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("salesDate", true)}
                    className="picker-up"
                  />
                  {formik.touched.salesDate && !!formik.errors.salesDate ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.salesDate}
                    </span>
                  ) : null}
                </Form.Group>

                <Button type="submit" className="w-100 submit-btn">
                  Add to Cart
                </Button>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showCustomerSelectorModal && (
        <CustomerSelectModal
          title="Select Pump Attendant"
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
        />
      )}
      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
    </>
  );
}
