import { Table, Button, Form, Modal, Row, Dropdown } from "react-bootstrap";
import {
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  GearIcon,
  PrintIcon,
} from "./Icons";
import "./../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import DateRangePicker from "./utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "./Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { reportActions } from "./../utils/reactQueryActions";
import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
  useQueryParams,
  useScrollTop,
} from "./../utils/hooks";
import queryString from "query-string";
import { services } from "./../config";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { paginationOptions, scrollToTop } from "./../utils/helpers";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import { useAuth } from "./../hooks/useAuth";
import PageHeader from "./PageHeader";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { toast } from "react-toastify";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty } from "lodash";
import LoginDialog from "./LoginDialog";
import ConfirmDialog from "./ConfirmDialogue";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { UnAuthorized } from "./utils/UnAuthorized";

const getSubCategories = (parentCat = "All", subCategories = []) => {
  subCategories = subCategories
    .filter((el) => el.Cat_Name === parentCat)
    .map((el) => ({ label: el.Sub_Cat, value: el.Sub_Cat }));
  return [{ label: "All", value: "All" }, ...subCategories];
};

function CreateCementPriceSettingModal({
  showCreateCementPriceSettingModal,
  setShowCreateCementPriceSettingModal,
  refetch,
}) {
  const backendUrl = useBackendUrl();

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/incentive/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.category = [
      {
        label: "All",
        value: "All",
      },
      ...data.categories.map((el) => ({
        value: el.Cat_Name,
        label: el.Cat_Name,
      })),
    ];

    data.subcategory = [
      {
        label: "All",
        value: "All",
      },
      ...data.subCategories.map((el) => ({
        value: el.Sub_Cat,
        label: el.Sub_Cat,
      })),
    ];

    data.region = data.region.map((el) => {
      return {
        value: el.region,
        label: el.region,
      };
    });

    data.depot = data.depot.map((el) => {
      return {
        value: el.Depot,
        label: el.Depot,
      };
    });

    data.plant = data.plant.map((el) => {
      return {
        value: el.Plant,
        label: el.Plant,
      };
    });

    data.plantAndDepot = [...data.depot, ...data.plant];

    console.log(data);

    return data;
  };

  const {
    data = {
      category: [],
      subcategory: [],
      region: [],
      depot: [],
      plant: [],
      subCategoriesWithParent: [],
      plantAndDepot: [],
    },
  } = useQuery(["INCENTIVE_SETUP"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const initialValues = {
    Plant_Depot: "All",
    Cat_Name: "All",
    Sub_Cat: "All",
    UnitCost: "",
    UnitPrice: "",
  };
  const schema = yup.object().shape({
    Plant_Depot: yup.string().required(),
    Cat_Name: yup.string().required(),
    Sub_Cat: yup.string().required(),
    UnitPrice: yup.number().required(),
    // UnitCost: yup.number().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      createPriceSettingsMutation.mutate({ setting: values });
    },
  });

  const createPriceSettings = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/items/create-price-settings`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createPriceSettingsMutation = useMutation(
    (payload) => createPriceSettings(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
        setShowCreateCementPriceSettingModal(false);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  return (
    <Modal
      show={showCreateCementPriceSettingModal}
      onHide={() => setShowCreateCementPriceSettingModal(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Add Pricing</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Plant / Depot</Form.Label>
            <Select
              classNamePrefix={"form-select"}
              options={data?.plantAndDepot}
              value={data?.plantAndDepot?.find(
                (el) => el.value === formik.values.Plant_Depot
              )}
              onChange={({ value }) => {
                formik.setFieldValue("Plant_Depot", value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Select
              classNamePrefix={"form-select"}
              options={data?.category}
              value={data?.category?.find(
                (el) => el.value === formik.values.Cat_Name
              )}
              onChange={({ value }) => {
                formik.setFieldValue("Cat_Name", value);
                formik.setFieldValue("Sub_Cat", "All");
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Sub Category</Form.Label>
            <Select
              classNamePrefix={"form-select"}
              options={getSubCategories(
                formik.values.Cat_Name,
                data?.subCategoriesWithParent
              )}
              value={getSubCategories(
                formik.values.Cat_Name,
                data?.subCategoriesWithParent
              ).find((el) => el.value === formik.values.Sub_Cat)}
              onChange={({ value }) => formik.setFieldValue("Sub_Cat", value)}
            />
          </Form.Group>

          <Row>
            <Form.Group className="mb-3 col-md-12">
              <Form.Label>Unit Price</Form.Label>
              <CurrencyCustomInput
                name="UnitPrice"
                value={formik.values.UnitPrice}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.UnitPrice && !!formik.errors.UnitPrice
                }
                placeholder="0.00"
              />
              {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.UnitPrice}
                </span>
              ) : null}
            </Form.Group>
            {/* 
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Unit Price</Form.Label>
              <CurrencyCustomInput
                name="UnitPrice"
                value={formik.values.UnitPrice}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.UnitPrice && !!formik.errors.UnitPrice
                }
                placeholder="0.00"
              />
              {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.UnitPrice}
                </span>
              ) : null}
            </Form.Group> */}
          </Row>

          <Button
            disabled={createPriceSettingsMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {createPriceSettingsMutation.isLoading ? "Please wait…" : "Save"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function CementPriceSettings() {
  const { backendUrl, token } = useAuth();
  const isAdmin = useIsAdmin();
  const [
    showCreateCementPriceSettingModal,
    setShowCreateCementPriceSettingModal,
  ] = useState(false);
  const [
    showEditCementPriceSettingModal,
    setShowEditCementPriceSettingModal,
  ] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState({});

  useScrollTop();
  const initialFilterParams = { q: "", page: 1, limit: 40 };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const getPriceSettings = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/items/price-settings?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { priceSettings: [] }, refetch } = useQuery(
    ["GET_PRICE_SETTINGS", queryParams],
    () => getPriceSettings(queryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const deletePriceSettings = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/items/delete-price-settings`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deletePriceSettingsMutation = useMutation(
    (payload) => deletePriceSettings(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = async (setting) => {
    setSelectedSetting(null);
    if (await LoginDialog()) {
      setSelectedSetting(setting);
      setShowEditCementPriceSettingModal(true);
    }
  };

  const handleDelete = async (setting) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Pricing",
          description:
            "Are you sure you want to delete this Pricing from your inventory",
        })
      ) {
        deletePriceSettingsMutation.mutate({ setting });
      }
    }
  };

  return (
    <>
      {isAdmin ? (
        <main className="inner-page-outlet">
          <PageHeader
            name="Price Settings"
            description=""
            icon={<GearIcon />}
          />
          <main className="cash-book">
            <div className="content">
              <header>
                <div className="d-flex flex-wrap gap-3 col-md-4">
                  <div className="global-search-area w-75">
                    <MagnifyIcon />
                    <Form.Control
                      className=""
                      name="q"
                      value={filterParams.q}
                      onChange={(e) => handleSearchQueryChange(e)}
                      placeholder="Search..."
                    />
                  </div>
                  <Button
                    onClick={() => refetch()}
                    variant=""
                    className="bg-light-blue border-0 text-primary"
                  >
                    <CachedIcon />
                  </Button>
                </div>

                <div className="actions mr-5">
                  <Button
                    onClick={() => setShowCreateCementPriceSettingModal(true)}
                    className="px-3"
                  >
                    + Create
                  </Button>
                </div>
              </header>

              <div className="px-md-4">
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Plant / Depot</th>
                      <th>Category</th>
                      <th>Sub Category</th>
                      {/*    <th>UnitCost</th> */}
                      <th>UnitPrice</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {data.priceSettings.map((el, index) => (
                      <tr key={index}>
                        <td>{data?.startIndex + index + 1}</td>
                        <td>{el.Plant_Depot}</td>
                        <td>{el.Cat_Name}</td>
                        <td>{el.Sub_Cat}</td>
                        {/*  <td>{currency(el.UnitCost, { symbol: "" }).format()}</td> */}
                        <td>
                          {currency(el.UnitPrice, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleEdit(el)}
                              >
                                <EditIcon /> Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleDelete(el)}
                              >
                                <DeleteIcon /> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                    scrollToTop();
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </main>

          {showCreateCementPriceSettingModal && (
            <CreateCementPriceSettingModal
              showCreateCementPriceSettingModal={
                showCreateCementPriceSettingModal
              }
              setShowCreateCementPriceSettingModal={
                setShowCreateCementPriceSettingModal
              }
              refetch={refetch}
            />
          )}

          {!isEmpty(selectedSetting) && (
            <EditCementPriceSettingModal
              showEditCementPriceSettingModal={showEditCementPriceSettingModal}
              setShowEditCementPriceSettingModal={
                setShowEditCementPriceSettingModal
              }
              selectedSetting={selectedSetting}
              setSelectedSetting={setSelectedSetting}
              refetch={refetch}
            />
          )}
        </main>
      ) : (
        <UnAuthorized />
      )}
    </>
  );
}

function EditCementPriceSettingModal({
  showEditCementPriceSettingModal,
  setShowEditCementPriceSettingModal,
  selectedSetting = {},
  refetch,
  setSelectedSetting,
}) {
  const backendUrl = useBackendUrl();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/incentive/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.category = [
      {
        label: "All",
        value: "All",
      },
      ...data.categories.map((el) => ({
        value: el.Cat_Name,
        label: el.Cat_Name,
      })),
    ];

    data.subcategory = [
      {
        label: "All",
        value: "All",
      },
      ...data.subCategories.map((el) => ({
        value: el.Sub_Cat,
        label: el.Sub_Cat,
      })),
    ];

    data.region = data.region.map((el) => {
      return {
        value: el.region,
        label: el.region,
      };
    });

    data.depot = data.depot.map((el) => {
      return {
        value: el.Depot,
        label: el.Depot,
      };
    });

    data.plant = data.plant.map((el) => {
      return {
        value: el.Plant,
        label: el.Plant,
      };
    });

    data.plantAndDepot = [...data.depot, ...data.plant];

    console.log(data);

    return data;
  };

  const {
    data = {
      category: [],
      subcategory: [],
      region: [],
      depot: [],
      plant: [],
      subCategoriesWithParent: [],
      plantAndDepot: [],
    },
  } = useQuery(["INCENTIVE_SETUP"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const initialValues = {
    Cat_Name: "All",
    Sub_Cat: "All",
    UnitCost: "",
    UnitPrice: "",
  };
  const schema = yup.object().shape({
    Cat_Name: yup.string().required(),
    Sub_Cat: yup.string().required(),
    UnitPrice: yup.number().required(),
    // UnitCost: yup.number().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      editPriceSettingsMutation.mutate({
        setting: values,
        oldSetting: selectedSetting,
      });
    },
  });

  useEffectOnce(() => {
    formik.setValues({
      Cat_Name: selectedSetting.Cat_Name,
      Sub_Cat: selectedSetting.Sub_Cat,
      UnitCost: selectedSetting.UnitCost,
      UnitPrice: selectedSetting.UnitPrice,
    });
  });

  const createPriceSettings = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/items/update-price-settings`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const editPriceSettingsMutation = useMutation(
    (payload) => createPriceSettings(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
        setShowEditCementPriceSettingModal(false);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  return (
    <Modal
      show={showEditCementPriceSettingModal}
      onHide={() => {
        setSelectedSetting(null);
        setShowEditCementPriceSettingModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Edit Pricing</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Plant / Depot</Form.Label>
            <Select
              classNamePrefix={"form-select"}
              options={data?.plantAndDepot}
              value={data?.plantAndDepot?.find(
                (el) => el.value === formik.values.Plant_Depot
              )}
              onChange={({ value }) => {
                formik.setFieldValue("Plant_Depot", value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Select
              classNamePrefix={"form-select"}
              options={data?.category}
              value={data?.category?.find(
                (el) => el.value === formik.values.Cat_Name
              )}
              onChange={({ value }) => {
                formik.setFieldValue("Cat_Name", value);
                formik.setFieldValue("Sub_Cat", "All");
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Sub Category</Form.Label>
            <Select
              classNamePrefix={"form-select"}
              options={getSubCategories(
                formik.values.Cat_Name,
                data?.subCategoriesWithParent
              )}
              value={getSubCategories(
                formik.values.Cat_Name,
                data?.subCategoriesWithParent
              ).find((el) => el.value === formik.values.Sub_Cat)}
              onChange={({ value }) => formik.setFieldValue("Sub_Cat", value)}
            />
          </Form.Group>

          <Row>
            <Form.Group className="mb-3 col-md-12">
              <Form.Label>Unit Price</Form.Label>
              <CurrencyCustomInput
                name="UnitPrice"
                value={formik.values.UnitPrice}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.UnitPrice && !!formik.errors.UnitPrice
                }
                placeholder="0.00"
              />
              {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.UnitPrice}
                </span>
              ) : null}
            </Form.Group>

            {/*  <Form.Group className="mb-3 col-md-6">
              <Form.Label>Unit Price</Form.Label>
              <CurrencyCustomInput
                name="UnitPrice"
                value={formik.values.UnitPrice}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.UnitPrice && !!formik.errors.UnitPrice
                }
                placeholder="0.00"
              />
              {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.UnitPrice}
                </span>
              ) : null}
            </Form.Group> */}
          </Row>

          <Button
            disabled={editPriceSettingsMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {editPriceSettingsMutation.isLoading
              ? "Please wait…"
              : "Save Changes"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
