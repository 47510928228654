import { useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Table, Form, Button, InputGroup } from "react-bootstrap";
import { services } from "../../config";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import { useQuery } from "react-query";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";

import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { NoSelectedItemIcon } from "../Icons";
import Datetime from "react-datetime";
import moment from "moment";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";

// Used for adding item for Bulk sale -
export default function AddCustomerItemModal({
  showItemSelectorModal,
  setShowItemSelectorModal,
  handleAddItem,
  drivers = [],
  driverType = [],
  selectedItemsBarcodes = [],
}) {
  const { backendUrl } = useAuth();
  const formik = useFormik({
    initialValues: {
      loadingDate: moment(),
      deliveryDate: moment(),
      salesDate: moment(),
      truckNumber: "",

      shippingCost: "",
      loadingCharge: "",
      offloadingCharge: "",

      selectedDriverType: "Self Collection",
      driver: "",
      driverPhoneNumber: "",
      Quantity: "300",

      supplyNow: true,
    },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {
      if (typeof values.loadingDate === "string") {
        formik.setFieldError("loadingDate", "Invalid date");
      }
      if (typeof values.deliveryDate === "string") {
        formik.setFieldError("deliveryDate", "Invalid date");
      }

      if (typeof values.salesDate === "string") {
        formik.setFieldError("salesDate", "Invalid date");
      }
      values.loadingDate = values.loadingDate.format();
      values.deliveryDate = values.deliveryDate.format();
      values.salesDate = values.salesDate.format();

      handleAddItem({
        item: selectedItem,
        values: values,
        sales: [
          {
            ...selectedItem,
            ...values,
            PriceSold: selectedItem.UnitPrice,
            Discount: 0,
            amountDue: selectedItem.UnitPrice,
            amountPaid: selectedItem.UnitPrice,
            balance: 0,
          },
        ],
      });
    },
  });

  // const quantityRef = useRef();
  const [selectedItem, setselectedItem] = useState(null);
  const [searchString, setSearchString] = useState("");
  const debouncedSearchString = useDebounce(searchString, 500);

  const getItems = async (q) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items?q=${q}&withPriceSetting=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] } } = useQuery(
    [queryActions.GET_ITEMS, debouncedSearchString],
    () => getItems(debouncedSearchString),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSelectedItem = (item) => {
    if (Number(item.UnitCost) <= 0) {
      return toast.error(
        `Unit Cost for this item has not been set: ${item.Cat_Name}`
      );
    }
    if (Number(item.UnitPrice) <= 0) {
      return toast.error(
        `Unit Price for this item has not been set: ${item.Cat_Name}`
      );
    }
    setselectedItem({ ...item, Quantity: 1, quantityInStock: item.Quantity });
  };

  return (
    <Modal
      show={showItemSelectorModal}
      onHide={() => setShowItemSelectorModal(false)}
      dialogClassName="item-select-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add Item</h1>
          <p>Select item and set the pricing details.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body-content">
          <div className="">
            <h2>Select Item</h2>

            <div className="items-table-area rounded">
              <div className="global-search-area m-3 mx-0">
                <MagnifyIcon />
                <input
                  className="form-control search-input"
                  name="q"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search item..."
                  autoFocus
                  autoComplete="off"
                />
              </div>

              <div className="content">
                <Table borderless striped responsive className="product-table">
                  <thead className="">
                    <tr>
                      <th scope="col">ATC</th>
                      <th scope="col">Item_Name</th>
                      {/* <th scope="col">Unit_Cost</th>
                      <th scope="col">Unit_Price</th> */}
                      <th scope="col">Quantity</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="blue-hover">
                    {data.items &&
                      data.items
                        .filter(
                          (el) =>
                            !selectedItemsBarcodes.includes(el.Bar_Code) &&
                            Number(el.Quantity) > 0
                        )
                        .map((el, index) => (
                          <tr
                            className="p-cursor"
                            key={index}
                            onClick={() => handleSelectedItem(el)}
                          >
                            <td>{el.Bar_Code}</td>
                            <td>{el.Item_Name}</td>
                            {/* <td>
                            {currency(el.UnitCost, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.UnitPrice, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td> */}
                            <td>{el.Quantity}</td>
                            <td>
                              {selectedItem?.Bar_Code === el.Bar_Code ? (
                                <Button variant="primary">Selected</Button>
                              ) : (
                                <Button variant="outline-primary">
                                  Select
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="postion-relative">
            {/*  No item */}
            {!selectedItem && (
              <div className="no-item">
                <div className="info">
                  <NoSelectedItemIcon />
                  <h2>No Item Selected</h2>
                  <p>
                    Please select an item from the table beside to fill in the{" "}
                    <br />
                    pricing details.
                  </p>
                </div>
              </div>
            )}
            <h2>Item Details</h2>

            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Label className="slim">Item Name</Form.Label>
                <p className="selected">{selectedItem?.Item_Name || "..."} </p>
              </Form.Group>

              <Form.Group className="mb-3 d-flex flex-wrap justify-content-between gap-3 ">
                <div>
                  <Form.Label className="slim">Item Code</Form.Label>
                  <p className="selected"> {selectedItem?.Bar_Code || "..."}</p>
                </div>

                <div>
                  <Form.Label className="slim">Quantity in Stock</Form.Label>
                  <p className="selected">
                    {selectedItem?.quantityInStock || "..."}
                  </p>
                </div>

                <div>
                  <Form.Label className="slim">Cost Price</Form.Label>
                  <p className="selected">
                    {currency(selectedItem?.UnitCost, {
                      symbol: "",
                    }).format() || "..."}
                  </p>
                </div>
              </Form.Group>
              <hr />

              <h2 className="pt-0">Pricing Details</h2>

              <div className="row">
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1" htmlFor="loadingDate">
                    Loading Date
                  </Form.Label>
                  <Datetime
                    dateFormat="MMM DD, YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    name="DOB"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.loadingDate &&
                        !!formik.errors.loadingDate
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.loadingDate}
                    onChange={(date) => {
                      formik.setFieldValue("loadingDate", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("loadingDate", true)}
                  />
                  {formik.touched.loadingDate && !!formik.errors.loadingDate ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.loadingDate}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1" htmlFor="deliveryDate">
                    Delivery Date
                  </Form.Label>
                  <Datetime
                    dateFormat="MMM DD, YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    name="deliveryDate"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.deliveryDate &&
                        !!formik.errors.deliveryDate
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.deliveryDate}
                    onChange={(date) => {
                      formik.setFieldValue("deliveryDate", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("deliveryDate", true)}
                  />
                  {formik.touched.deliveryDate &&
                  !!formik.errors.deliveryDate ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.deliveryDate}
                    </span>
                  ) : null}
                </Form.Group>
              </div>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>Delivery</Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="Select"
                  menuPosition="fixed"
                  menuShouldScrollIntoView={false}
                  isSearchable={false}
                  value={driverType.find(
                    (el) => el.value === formik.values.selectedDriverType
                  )}
                  options={driverType}
                  onChange={({ value }) =>
                    formik.setFieldValue("selectedDriverType", value)
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.driver}
                </Form.Control.Feedback>
              </Form.Group>

              {formik.values.selectedDriverType === "Self Collection" ? (
                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Driver</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Select"
                    menuPosition="fixed"
                    menuShouldScrollIntoView={false}
                    isSearchable={true}
                    value={drivers.find(
                      (el) => el.value === formik.values.driver
                    )}
                    options={drivers}
                    onChange={({ value }) =>
                      formik.setFieldValue("driver", value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.driver}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : (
                <>
                  <Form.Group className="mb-3 pb-1">
                    <Form.Label>Driver</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Driver's Name"
                      name="driver"
                      value={formik.values.driver}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.driver && !!formik.errors.driver
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.driver}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3 pb-1">
                    <Form.Label>Driver Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter Phone number"
                      name="driverPhoneNumber"
                      value={formik.values.driverPhoneNumber}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.driverPhoneNumber &&
                        !!formik.errors.driverPhoneNumber
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.driverPhoneNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              <Form.Group className="mb-3 pb-1">
                <Form.Label>Truck Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Truck Number"
                  name="truckNumber"
                  value={formik.values.truckNumber}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.truckNumber && !!formik.errors.truckNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.truckNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="row">
                <Form.Group className="col mb-3 pb-1">
                  <Form.Label>Loading Charge</Form.Label>
                  <CurrencyCustomInput
                    name="loadingCharge"
                    value={formik.values.loadingCharge}
                    onValueChange={(value, name) =>
                      formik.setFieldValue(name, value)
                    }
                    placeholder="0.00"
                    decimalsLimit={2}
                  />
                </Form.Group>

                <Form.Group className="col mb-3 pb-1">
                  <Form.Label>Offloading Charge</Form.Label>
                  <CurrencyCustomInput
                    name="offloadingCharge"
                    placeholder="0.00"
                    decimalsLimit={2}
                    value={formik.values.offloadingCharge}
                    onValueChange={(value, name) =>
                      formik.setFieldValue(name, value)
                    }
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label>Shipping Cost</Form.Label>
                  <CurrencyCustomInput
                    name="shippingCost"
                    placeholder="0.00"
                    decimalsLimit={2}
                    value={formik.values.shippingCost}
                    onValueChange={(value, name) =>
                      formik.setFieldValue(name, value)
                    }
                  />
                </Form.Group>

                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label>Quantity</Form.Label>
                  <NumberCustomInput
                    placeholder="0"
                    name="Quantity"
                    value={formik.values.Quantity}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value, true);
                    }}
                  />
                </Form.Group>
              </div>

              <Button
                disabled={!selectedItem}
                type="submit"
                className="w-100 submit-btn"
              >
                Add to Cart
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
