import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  convertMomentDate,
  customerFullName,
  employeeFullName,
  maxTopPopperConfig,
  reactSelectTheme,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import AccountPlusOutlineIcon from "mdi-react/AccountPlusOutlineIcon";
import { isEmpty } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, { useUpdateEffect } from "../../utils/hooks";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import { Popover } from "react-tiny-popover";
import AddCustomerItemModal from "./AddCustomerItemModal";
import moment, { isMoment } from "moment";
import { format } from "date-fns";
import CementEditRow from "./CementEditRow";
import AddMeterModal from "./AddMeterModal";
import MeterEditRow from "./MeterEditRow";
import { useAuth } from "../../hooks/useAuth";

export default function CreateMeterInvoice() {
  const itemOverlayRef = useRef();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer] = useState(null);

  const [showMeterSelectorModal, setShowMeterSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );

  const { backendUrl } = useAuth();
  const [tableData, setTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(0);
  const [editedSaleIndex, setEditedSaleIndex] = useState(0);

  const [salesForm, setSaleForm] = useState({});

  const driverType = [
    {
      label: "Self Collection",
      value: "Self Collection",
    },
    {
      label: "Direct Delivery",
      value: "Direct Delivery",
    },
  ];

  const createInvoice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createInvoiceMutation = useMutation(
    (payload) => createInvoice(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);

        formik.resetForm();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      /*  //  tax: "",
      taxType: "None",
      PaymentType: "Cash",
      proformaNumber: "",
      splitPaymentType: "",
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      dueIn: 1, */

      Quantity: 1,
      PriceSold: 0,
      Discount: 0,
      loadingDate: moment(),
      deliveryDate: moment(),
      salesDate: moment(),
      truckNumber: "",
      driver: "",
      shippingCost: "",
      loadingCharge: "",
      offloadingCharge: "",
    },
    validationSchema: yup.object().shape({
      // PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
      Quantity: yup
        .string()
        .required("required")
        .min(1),
      PriceSold: yup.string().required("required"),
    }),
    onSubmit: (values) => {},
    onReset: () => {},
  });

  const submit = () => {};

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));
    data.drivers = data.drivers
      .map((el) => ({
        label: employeeFullName(el),
        value: employeeFullName(el),
      }))
      .filter((el) => el.label);

    data.meters = data.meters.map((el) => ({
      value: el.Meter_ID,
      label: el.Meter_Name,
      ...el,
    }));

    console.log(data.meters);
    return data;
  };

  const { data = { invoiceCat: [], drivers: [], meters: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const setSelectedCustomer = (customerData) => {
    tableData[editedItemIndex] = {
      ...tableData[editedItemIndex],
      customer: customerData,
    };
    setTableData([...tableData]);
  };

  const handleAddMeter = (meter) => {
    if (tableData.find((el) => el.meterId === meter.meterId)) {
      return toast.error("Meter already added");
    }
    const newTableData = [...tableData, meter];
    setTableData(newTableData);
    setShowMeterSelectorModal(false);
  };

  const handleRemoveMeter = (index) => {
    const newTableData = tableData.filter((el, i) => index !== i);
    setTableData([...newTableData]);
  };

  const setActiveRow = (index) => {
    setEditedItemIndex(index);
  };

  const handleEditCustomer = (index) => {
    setActiveRow(index);
    setShowCustomerSelectorModal(true);
  };

  const editMeterSale = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const createMeterInvoice = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/invoice/create-meter-invoice`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createMeterInvoiceMutation = useMutation(
    (payload) => createMeterInvoice(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setTableData([]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const sendInvoice = () => {
    const payload = tableData.map((el) => ({
      ...el,
      items: [{ ...el, salesDate: convertMomentDate(el.salesDate) }],
    }));

    createMeterInvoiceMutation.mutate({
      meterSales: payload,
    });
  };

  return (
    <main className="create-invoice">
      <PageHeader
        name="Meter Reading"
        description="Create a new transaction invoice with your customers."
        icon={<CreateInvoiceIcon />}
      />
      <div className="p-3 content">
        <div className="d-flex content-holder rounded">
          <section className="item-details customers">
            <div>
              <header>
                <h1>Invoice</h1>
              </header>
              {/*   <div className="actions">
               <div>
                  <h2>Item Details</h2>
                  <p>Add items in to the invoice table. </p>
                </div> 
                <div>{"  "}</div>
              </div> */}

              <div className="selected-data-area mt-3">
                <div className="table-holder">
                  <Table
                    responsive
                    borderless
                    hover
                    striped
                    className="product-table text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th />

                        <th>Meter </th>
                        <th>Pump Attendant</th>
                        <th>Item</th>
                        <th>Item Name</th>

                        <th>Opening reading</th>
                        <th>Closing reading</th>
                        <th>Quantity</th>

                        <th>Price Sold</th>

                        <th>Discount</th>
                        <th>Product name</th>
                        <th>Unit Price</th>
                        <th>Profit</th>
                        <th>Cost</th>
                        <th>Type</th>

                        <th>Shipping Cost</th>

                        <th>Sales Date</th>
                        <th>Proforma No</th>
                        <th>Tax Type</th>
                        <th>Tax Amount</th>
                        <th>Payment Type</th>
                        <th>Subtotal</th>
                        <th>Amount Due</th>
                        <th>Amount Paid</th>

                        <th>Balance</th>
                        <th>Due In (days)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((el, index) => (
                        <Fragment key={el.meterId}>
                          <MeterEditRow
                            index={index}
                            sale={el}
                            handleRemoveMeter={handleRemoveMeter}
                            handleEditCustomer={handleEditCustomer}
                            editMeterSale={editMeterSale}
                          />
                        </Fragment>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {/*  */}

                <button
                  onClick={() => setShowMeterSelectorModal(true)}
                  type="button"
                  className="btn text-primary my-3 d-flex align-items-center gap-2"
                >
                  <AddCircleIcon />
                  Add Meter Reading
                </button>

                {/*  No item  */}
                {isEmpty(tableData) ? (
                  <div className="no-item my-5">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">Haven't selected an item yet</h2>
                      <p>
                        You can click +Add Item Button to add an item to the
                        table.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {!isEmpty(tableData) && (
              <div className="d-flex justify-content-between total-info">
                {/*  <table className="table table-borderless balance">
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td />
                    </tr>

                    <tr>
                      <td>Discount</td>
                      <td>
                       
                      </td>
                    </tr>

                    <tr>
                      <td>Amount Due</td>
                      <td />
                    </tr>

                    <tr>
                      <td>Balance</td>
                      <td />
                    </tr>

                    <tr>
                      <td>Total</td>
                      <td />
                    </tr>
                  </tbody>
                </table> */}
                <div />
                <div>
                  <Button
                    onClick={() => sendInvoice()}
                    //  disabled={canNotCreateInvoice}
                    type="button"
                    className="py-3 px-5"
                  >
                    Post Sales
                  </Button>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>

      {showCustomerSelectorModal && (
        <CustomerSelectModal
          title="Select Pump Attendant"
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          // selectedCustomer={selectedCustomer}
        />
      )}

      {showMeterSelectorModal && (
        <AddMeterModal
          showMeterSelectorModal={showMeterSelectorModal}
          setShowMeterSelectorModal={setShowMeterSelectorModal}
          handleAddMeter={handleAddMeter}
          meters={data.meters}
        />
      )}
    </main>
  );
}
