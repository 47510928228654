import PageHeader from "./PageHeader";
import { Dropdown, Table, Form, Button, Row, Col } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
} from "./Icons";
import "./../assets/scss/reports/cashbook.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useEffectOnce,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
  useSingleAndDoubleClick,
} from "../utils/hooks";
import { services } from "../config";
import { reportActions } from "../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  reactSelectTheme,
} from "../utils/helpers";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import queryString from "query-string";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { useMemo } from "react";
import { first } from "lodash";
import { parseInt } from "lodash";
import { last } from "lodash";
import SplitAtcModal from "./SalesAndInvoicing/SplitAtcModal";
import { useAuth } from "../hooks/useAuth";
import ModalLoader from "./utils/ModalLoader";

function AtcEditRow({ item, index, edit }) {
  const initialValues = {
    Trans_ID: "",
    freight: "",
    UnitPrice: "",
    UnitCost: "",
    Item_Name: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
    onReset: () => {},
  });

  const subTotal = useMemo(() => {
    return currency(formik.values.UnitCost, {
      symbol: "",
      separator: "",
    })
      .multiply(item.Quantity)
      .format();
  }, [formik.values.UnitCost, item?.Quantity]);

  const populateForm = () => {
    formik.setValues({
      ...formik.values,
      // The first item used to populate the orders will need some default values
      UnitPrice: item.UnitPrice ? item.UnitPrice : 0,
      UnitCost: item.UnitCost ? item.UnitCost : 0,
      Item_Name: item.Item_Name ? item.Item_Name : "Cement",
      Trans_ID: item.newTrans_ID ? item.newTrans_ID : "",
      freight: item.freight ? item.freight : "",
    });

    setHoldUnitCost(item.UnitCost ? item.UnitCost : "4200");
  };

  useEffectOnce(() => {
    populateForm();
  });

  // hold for when user is entered freight so we can add to a fixed number
  const [holdUnitCost, setHoldUnitCost] = useState(formik.values.UnitCost);

  const handleFreightChange = (freight) => {
    const freightForOne = currency(freight, {
      symbol: "",
      separator: "",
    })
      .divide(item.Quantity)
      .format();

    const newUnitCost = currency(freightForOne, {
      symbol: "",
      separator: "",
    })
      .add(holdUnitCost)
      .format();
    formik.setFieldValue("UnitCost", newUnitCost);
  };

  const formValues = useDebounce(formik.values, 500);

  useEffect(() => {
    // formik values is set empty to undefined
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }
    edit(index, { ...formValues, subTotal });
  }, [formValues, subTotal]);

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <Form.Control
          type="text"
          placeholder=""
          name="Trans_ID"
          value={formik.values.Trans_ID}
          onChange={formik.handleChange}
          className="fw-bold"
          style={{ minWidth: "15rem" }}
        />
      </td>
      <td>
        {" "}
        <Form.Control
          type="text"
          placeholder=""
          name="Item_Name"
          value={formik.values.Item_Name}
          onChange={formik.handleChange}
        />
      </td>
      <td>
        <CurrencyCustomInput
          name="UnitCost"
          value={formik.values.UnitCost}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
            setHoldUnitCost(value);

            // any freight will be invalid - so clear
            formik.setFieldValue("freight", 0);
          }}
          placeholder="0.00"
          currencySymbol=""
        />
      </td>
      <td>
        {currency(item?.Quantity, {
          symbol: "",
        }).format()}
        {}
      </td>
      <td>
        {currency(subTotal, {
          symbol: "",
        }).format()}
      </td>
      <td>
        <CurrencyCustomInput
          name="UnitPrice"
          value={formik.values.UnitPrice}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          placeholder="0.00"
          currencySymbol=""
        />
      </td>
      <td>
        <span className="text-nowrap">{item?.Pickup_Location}</span>
      </td>
      <td>
        <CurrencyCustomInput
          name="freight"
          value={formik.values.freight}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
            handleFreightChange(value);
          }}
          placeholder="0.00"
          currencySymbol=""
        />
      </td>
      <td>{item?.vendor?.CompanyName}</td>
      <td>{item?.PaymentType}</td>
      <td>
        <span className="text-nowrap">
          {item?.Post_Date
            ? `${format(new Date(item?.Post_Date), "MMM dd, yyyy")}`
            : ""}
        </span>
      </td>
    </tr>
  );
}

// -----------------------------

export default function LoadAtc() {
  const { backendUrl, token } = useAuth();
  const truckCapacityOptions = [
    {
      label: "900",
      value: 900,
    },
    {
      label: "600",
      value: 600,
    },
    {
      label: "300",
      value: 300,
    },
  ];

  const [vendorLedger, setVendorLedger] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [Trans_ID, setTrans_ID] = useState(null);
  const [showAtcModal, setShowAtcModal] = useState(false);
  const [hasSplit, setHasSplit] = useState(false);

  const searchClick = useSingleAndDoubleClick(
    () => {},
    () => setShowAtcModal(true)
  );

  const formik = useFormik({
    initialValues: {
      truckCapacity: 900,
    },
    onSubmit: (values) => {},
    onReset: () => {},
  });

  // const debouncedFormValuesTrans_ID = useDebounce(Trans_ID, 500);

  const getVendorLedger = async (Trans_ID) => {
    let response = await fetch(`${backendUrl}/api/vendors/ledger/${Trans_ID}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const getVendorLedgerMutation = useMutation(
    (payload) => getVendorLedger(payload),
    {
      onSuccess: ({ vendorLedger }) => {
        if (isEmpty(vendorLedger))
          return toast.error(
            "Ref Number does not exist or has been processed already"
          );
        handleSelectedAtc(vendorLedger);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const sendSplitItems = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/items/add-split-items`, {
      method: "POST",
      headers: {
        /*   Accept: "Application/json",
        "Content-Type": "Application/json", */
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const postSplitItemsMutation = useMutation(
    (payload) => sendSplitItems(payload),
    {
      onSuccess: ({ totalLoadedQuantity }) => {
        vendorLedger.containedQuantity = 0;
        setVendorLedger({ ...vendorLedger });
        toast.success("Success");
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const splitAtc = () => {
    const increment = (number, by = 1) => {
      return currency(number, {
        symbol: "",
        separator: "",
      })
        .add(by)
        .format();
    };

    const firstItem = first(tableData);
    if (!firstItem?.Trans_ID) {
      return toast.error("Please enter the first ATC Number");
    }

    const newTableData = [];
    const quantityToSplit = Number(vendorLedger?.Qty);

    const canSplit = currency(quantityToSplit, {
      symbol: "",
      separator: "",
    })
      .divide(formik.values.truckCapacity)
      .format();

    // console.log(quantityToSplit, formik.values.truckCapacity);

    if (canSplit != Infinity) {
      const canSplitNumber = Math.trunc(Number(canSplit));

      if (Number(canSplitNumber) > 0) {
        for (let i = 0; i < canSplitNumber; i++) {
          newTableData.push({
            ...firstItem,
            Quantity: formik.values.truckCapacity,
            newTrans_ID: `${firstItem.Trans_ID.slice(0, -1)}${parseInt(
              increment(last(firstItem.Trans_ID), i)
            )}`,
          });
        }
      }
    }

    const remaining =
      Number(quantityToSplit) % Number(formik.values.truckCapacity);
    if (remaining) {
      newTableData.push({
        ...firstItem,
        Quantity: remaining,
        newTrans_ID: `${firstItem.Trans_ID.slice(0, -1)}${parseInt(
          increment(last(firstItem.Trans_ID), newTableData.length)
        )}`,
      });
    }

    //  console.log(canSplit, vendorLedger, canSplitNumber, remaining);

    setTableData([...newTableData]);
    setHasSplit(true);
  };

  const edit = (index, values) => {
    tableData[index] = {
      ...tableData[index],
      ...values,
    };
    setTableData([...tableData]);
  };

  const totalLoadedQuantity = useMemo(() => {
    return tableData
      .map((el) => el.Quantity)
      .reduce(
        (a, b) =>
          currency(a, {
            symbol: "",
            separator: "",
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  const post = () => {
    if (!vendorLedger) return toast.error("No selection found");
    if (!hasSplit) return toast.error("Please split first");
    if (Number(vendorLedger.containedQuantity) > 0) {
      postSplitItemsMutation.mutate({
        items: tableData,
        totalLoadedQuantity,
        salesDate: vendorLedger.Post_Date,
        Trans_ID: vendorLedger.Trans_ID,
      });
    } else {
      toast.error("Already sent before");
    }
  };

  const handleSelectedAtc = (vendorLedger) => {
    setHasSplit(false);
    setTableData([]);
    setTrans_ID(vendorLedger.Trans_ID);
    setVendorLedger(vendorLedger);

    const newTableData = [
      { ...vendorLedger, Quantity: formik.values.truckCapacity },
    ];

    setTimeout(() => {
      setTableData(newTableData);
      setShowAtcModal(false);
    }, 50);
  };

  const handleSelectedAtcFromModal = (vendorLedger) => {
    getVendorLedgerMutation.mutate(vendorLedger.Trans_ID);
  };

  const stockValue = useMemo(() => {
    return tableData
      .map((el) => el.subTotal)
      .reduce(
        (a, b) =>
          currency(a, {
            symbol: "",
            separator: "",
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  return (
    <main className="inner-page-outlet">
      <PageHeader
        name="ATC Stocking"
        description=""
        icon={<ChartPieSliceTitleIcon />}
      />

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body pt-4">
              <div className="mb-3">
                <Row className="mx-4">
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Trans Date
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          readOnly
                          className="fw-5"
                          defaultValue={
                            vendorLedger
                              ? `${format(
                                  new Date(vendorLedger.Post_Date),
                                  "MMM dd, yyyy"
                                )}`
                              : ""
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Region
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          readOnly
                          className="fw-5"
                          defaultValue={
                            vendorLedger?.Pickup_Location?.split("/")[0]
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Vendor
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          readOnly
                          className="fw-5"
                          defaultValue={vendorLedger?.vendor?.CompanyName}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mx-4">
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Invoice No
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          value={Trans_ID}
                          name="Trans_ID"
                          onChange={(e) => setTrans_ID(e.target.value)}
                          onKeyDown={(e) =>
                            e.keyCode === 13 &&
                            getVendorLedgerMutation.mutate(e.target.value)
                          }
                          onClick={searchClick}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Plant/Depot
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          readOnly
                          className="fw-5"
                          defaultValue={
                            vendorLedger?.Pickup_Location?.split("/")[1]
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Product Cat
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          readOnly
                          className="fw-5"
                          defaultValue={vendorLedger?.Category}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mx-4">
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Contained Quantity
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          readOnly
                          className="fw-5"
                          defaultValue={vendorLedger?.Qty}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Truck Capacity
                      </Form.Label>
                      <Col md="8">
                        <Select
                          classNamePrefix={"form-select"}
                          isSearchable={false}
                          value={truckCapacityOptions.find(
                            (el) => el.value === formik.values.truckCapacity
                          )}
                          options={truckCapacityOptions}
                          onChange={({ value }) =>
                            formik.setFieldValue("truckCapacity", value)
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Col md="12" className="d-flex flex-wrap gap-3">
                        <Button
                          variant="light-blue"
                          className="text-primary px-4 py-2 d-flex align-items-center justify-content-center gap-2"
                          onClick={() => handleSelectedAtc(vendorLedger)}
                        >
                          <CachedIcon />
                          Refresh
                        </Button>
                        <Button
                          variant="light-blue"
                          className="text-primary px-5 py-2"
                          onClick={() => splitAtc()}
                        >
                          Split
                        </Button>

                        {"  "}

                        <Button onClick={() => post()} className="px-5 py-2">
                          Post
                        </Button>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row className="mx-4">
                  <Col md="4">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column md="4">
                        Quantity Loaded
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          readOnly
                          className="fw-5"
                          defaultValue={vendorLedger?.containedQuantity}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row> */}
              </div>

              <div className="px-4">
                <Table
                  responsive
                  borderless
                  striped
                  className="product-table load-atc text-nowrap"
                >
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>ATC No.</th>
                      <th>Item Name</th>
                      <th>Unit Cost</th>
                      <th>Quantity</th>
                      <th>SubTotal</th>
                      <th>Unit Price</th>
                      <th>Depot</th>
                      <th>Freight</th>
                      <th>Vendor</th>
                      <th>Payment</th>
                      <th>Req Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      tableData.map((el, index) => (
                        <Fragment key={index}>
                          <AtcEditRow item={el} index={index} edit={edit} />
                        </Fragment>
                      ))}
                  </tbody>
                </Table>
              </div>
              <div className={`summaryNew border-top mt-3`}>
                <div className="summaryHeader">
                  <h2>Summary</h2>
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(stockValue, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Stock Value</p>
                    </div>
                  </div>
                </div>

                <div className="d-flex" />
              </div>
            </div>
          </div>
        </div>
      </main>

      {showAtcModal && (
        <SplitAtcModal
          setShowAtcModal={setShowAtcModal}
          handleSelectedAtc={handleSelectedAtcFromModal}
        />
      )}

      <ModalLoader show={postSplitItemsMutation.isLoading} />
    </main>
  );
}
