import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  NoSelectedItemIcon,
  MoneyDollarIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import PageHeader from "../PageHeader";

function BankAccountAnalysis() {
  useScrollTop();
  const initialFilterParams = {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
  };

  const { backendUrl } = useAuth();
  const [activeRow, setActiveRow] = useState(null);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummaryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal/bank-accounts?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { count: 0, bankAccounts: [], banks: [] } } = useQuery(
    ["GET_BANK_ACCOUNTS_RCORDS", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  return (
    <main className="inner-page-outlet">
      <PageHeader
        name="Bank Transactions"
        description=""
        icon={<MoneyDollarIcon />}
      />
      <main className="cash-book">
        <div className="content">
          <header>
            <h1>
              <button className="btn filter">
                <FilterTwoIcon />
              </button>
              Bank Transactions
              <button className="btn text-primary">
                <CachedIcon />
              </button>
            </h1>
            <div className=" actions mr-5">
              <RsDateRangePicker
                placement="bottomEnd"
                defaultValue={
                  queryParams.startDate && queryParams.endDate
                    ? [
                        parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                        parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                    : []
                }
                onClean={() => clearDateRange()}
                onOk={(date) => filterByDateRange(date)}
              />
              <div className="transDropdown form-row d-flex gap-2 align-items-center ">
                <label className="col-form-label">Bank Name</label>
                <select
                  name="All Banks"
                  className="form-select"
                  value={filterParams.BankName}
                  onChange={(e) =>
                    setQueryParams({
                      ...queryParams,
                      BankName: e.target.value,
                    })
                  }
                >
                  <option value="">All Bank</option>
                  {data.banks.map((el, index) => (
                    <option key={index} value={el.BankName}>
                      {el.BankName}
                    </option>
                  ))}
                </select>
              </div>

              <button
                onClick={() => setBtnSummaryActive(true)}
                className="btn summaryBtnWhite "
              >
                Show Summary
              </button>
            </div>
          </header>

          <div className="mx-4">
            <Table responsive borderless striped className="product-table">
              <thead>
                <tr>
                  <th>Trans Date</th>
                  <th>Bank Name</th>
                  <th>Doc Type</th>
                  <th>Customer</th>
                  <th>Trans ID</th>
                  <th>Payment Type</th>
                  <th>Deposits</th>
                  <th>Withdrawals</th>
                  {/*  <th>Balance</th> */}
                  <th>Username</th>
                </tr>
              </thead>
              <tbody>
                {data.bankAccounts.map((el) => (
                  <tr>
                    <td>
                      {el?.Post_Date
                        ? format(new Date(el.Post_Date), "dd MMM, yyyy")
                        : "..."}
                    </td>
                    <td>{el.BankName}</td>
                    <td>{el.DocType}</td>
                    <td>{el.Customer}</td>
                    <td>{el.Trans_ID}</td>
                    <td>{el.PaymentType}</td>
                    <td>
                      {currency(el.Credit, {
                        symbol: "",
                      }).format()}
                    </td>
                    <td>
                      {currency(el.Debit, {
                        symbol: "",
                      }).format()}
                    </td>
                    {/* <td>{el.Balance}</td> */}
                    <td>{el.Username}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-between px-3 align-items-center pagination">
            {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
            <div className="pagination_left">
              <p className="m-0 p-0">Show</p>
              <select
                value={queryParams.limit}
                name="limit"
                className="form-select "
                onChange={(e) => handleSearchQueryChange(e)}
              >
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="30">30 rows</option>
                <option value="40">40 rows</option>
                <option value="50">40 rows</option>
              </select>
            </div>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>

          {/* <div className="p-4 border-top">
        <h2>Summary</h2>
        <div className="summary">
          <table className="table table-borderless balance">
            <tbody>
              <tr>
                <td>Total Cash Sales</td>
                <td>5,800.00</td>
              </tr>

              <tr>
                <td>Total Cash PMT</td>
                <td>350,250,895.00</td>
              </tr>

              <tr>
                <td>Total Credit/Debit Card/Cheque</td>
                <td>1,204,449,629.06</td>
              </tr>

              <tr>
                <td>Total Cash</td>
                <td>350,256,695.00</td>
              </tr>
            </tbody>
          </table>
          <table className="table table-borderless balance">
            <tbody>
              <tr>
                <td>Total Credit Sales</td>
                <td>1,688,912,055.73</td>
              </tr>

              <tr>
                <td>Total Posted</td>
                <td>3,243,668,379.79</td>
              </tr>
            </tbody>
          </table>
          <table className="table table-borderless balance">
            <tbody>
              <tr>
                <td>Total Expenses</td>
                <td>0.00</td>
              </tr>

              <tr>
                <td>POS to Bank</td>
                <td>103,366,800.00</td>
              </tr>

              <tr>
                <td>Direct Bank Transfer</td>
                <td>1,091,328,596.19</td>
              </tr>

              <tr>
                <td>Cash Sent to Bank</td>
                <td>0.00</td>
              </tr>

              <tr>
                <td>Cash at Hand</td>
                <td>337,732,030.10</td>
              </tr>btnSummaryActive
            </tbody>
          </table>
        </div>
      </div> */}

          <div
            className={
              btnSummaryActive ? `summaryNew border-top open` : `summaryNew `
            }
          >
            <div className="gridCont">
              <div className="gridChild gridChildBorderLeftBlue">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {currency(data.debitSum, { symbol: "" }).format()}
                  </p>
                  <p className="gridChld2">Total Trans Amount</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>5%</p>
                </div>
              </div>

              <div className="gridChild gridChildBorderLeftOrange">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {" "}
                    {currency(data?.creditSum, { symbol: "" }).format()}
                  </p>
                  <p className="gridChld2">Total Credit Amount</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconUp />
                  <p>5%</p>
                </div>
              </div>

              <div className="gridChild gridChildBorderLeftBrown">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {" "}
                    {currency(data?.creditSum, { symbol: "" })
                      .subtract(data.debitSum)
                      .format()}
                  </p>
                  <p className="gridChld2">Balance</p>
                </div>

                <div className="gridChildRight">
                  <SummaryChartIconDown />
                  <p>5%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
}

export default BankAccountAnalysis;
